import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import Footer from 'components/layout/Footer';
import Navbar from 'components/layout/Navbar';
import React, { useCallback } from 'react';
import config from 'config';
import fetchDocumentSettings from 'utils/sanity/fetchDocumentSettings';
import styled from '@emotion/styled';
import theme from 'theme/index';

const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: space-between;
`;

const Background = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.secondary.contrastText};
  gap: ${theme.spacing(4)};
  align-items: center;
  padding: ${theme.spacing(5, 3)};
  margin-top: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(10)};
  border-radius: ${theme.shape.borderRadius};
  box-shadow: 1px 2px 3px #00000040;
`;

const StatusCode = styled.div`
  font-family: Simsun;
  font-size: 8rem;
  line-height: 8rem;
  color: ${theme.palette.secondary.main};
`;

const Info = styled.div`
  font-family: Yu Gothic;
  color: ${theme.palette.secondary.main};
  font-size: 1.5rem;
`;

interface ErrorProps {
  settings: any;
  statusCode?: string;
  text?: string;
}

const Error = ({
  settings,
  statusCode,
  text,
}: ErrorProps): JSX.Element => {
  const {
    t,
  } = useTranslation();
  const router = useRouter();

  const handleButtonClick = useCallback(() => {
    router.push('/');
  }, [router,]);

  return <PageContainer>
    <Navbar />
    <Background>
      <Container>
        <StatusCode>{statusCode || '404'}</StatusCode>
        <Info>{text || t('Page not found')}</Info>
        <Button variant='contained' onClick={handleButtonClick}>
          {t('Go to the front page')}
        </Button>
      </Container>
    </Background>
    <Footer />
  </PageContainer>;
}

  ;

export const getStaticProps = async ({
  locale,
}) => {
  const settings = await fetchDocumentSettings();

  return {
    props: {
      ...settings,
      ...await serverSideTranslations(locale, ['common',]),
    },
    revalidate: config.environment.target === 'production' ?
      60 * 10 : 1,
  };
};

export default Error;
